.copy_trade .form-group>span{
    color: #cfcfcf;
    margin-bottom: 6px !important;
    display: block;
}
.copy_trade .content{
    padding-top: 15px;
}
.copy_trade input, .copy_trade select{
    background: rgb(0 0 0 / 7%) !important;
    color: white !important;
}
.copy_trade input:disabled, .copy_trade select:disabled{
    background: rgb(68 116 118 / 33%) !important;
    cursor: not-allowed;
}
.copy_trade select option{
    color: black !important;
}
.copy_trade .input-prepend, .copy_trade .input-append{
    color: white;
    padding: 0 10px !important;
    background: rgb(0 0 0 / 7%) !important;
    border: 1px solid #e0e7ff;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
}
.copy_trade .input-prepend{
    border-right: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.copy_trade .input-append{
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.copy_trade .input-group-prepend input{
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.copy_trade .input-group-append input{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
@media only screen and (max-width: 1024px){
    .white-theme.mobile-version .copy_trade .content[data-v-22dfce66] {
         padding-top: 12px;
    }
}
.white-theme .copy_trade .banner .content[data-v-22dfce66]{
    position: initial;
}
.copy_trade .customChartPercent[data-v-417299ea]{
    background-color: green;
}
.customChartPercent .percentWin[data-v-417299ea] {
    position: absolute;
    top: 0;
    right: 0;
    height: 10px;
    width: 0;
    background-color: #8b8b8b;
    border-radius: 0;
    transition: width 300ms ease-in-out;
}

.expert-group{
    color: white;
    flex-direction: row;
}
.expert-group .expert-list{
    display: flex;
    gap: 10px;
    margin-top: 40px;
}
.expert-group .expert-item{
    color: white;
    border: 1px solid #3600c9;
    border-radius: 8px;
    padding: 12px;
    width: fit-content;
    background: #15004e;
    cursor: pointer;
}
.expert-group .expert-item:hover{
    border-color: whitesmoke;
}
.expert-group .expert-item div{
    margin-bottom: 5px;
}
.expert-group .expert-item div>span:first-child{
    color: grey;
    margin-right: 3px;
}

.btn-go-back{
    height: 30px;
    line-height: 0;
    background: rgb(98 111 122 / 36%);
    color: white;
}
.btn-go-back i{
    color: #2177ff !important;
}
