.visibleHiden[data-v-f99ecb42] {
  visibility: hidden;
  opacity: 0;
}
.boxAuthentication[data-v-f99ecb42] {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    transition: opacity .3s ease .3s, left 0s ease .7s;
}
.boxAuthentication.show[data-v-f99ecb42] {
    right: 0;
}
.boxAuthentication.show .contentAuthentication[data-v-f99ecb42] {
    transform: translateX(0);
}
.boxAuthentication .contentAuthentication[data-v-f99ecb42] {
    position: relative;
    margin-left: auto;
    max-width: 428px;
    height: 100%;
    padding: 2rem;
    background-color: #060642;
    transform: translateX(428px);
    transition: transform .3s ease .3s;
}
.boxAuthentication .contentAuthentication .close-page[data-v-f99ecb42] {
    position: static !important;
}
.logoLogin[data-v-f99ecb42] {
    display: block;
    height: 40px;
    width: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
}
#myVideo[data-v-f99ecb42] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.overlay[data-v-f99ecb42] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #060642;
    background: linear-gradient(180deg, #0e0031 100%, #060642 0%);
}
.boxContent[data-v-f99ecb42] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.wbo-title[data-v-f99ecb42] {
    line-height: normal;
}
.wbo-title.titleLarge[data-v-f99ecb42] {
    color: #fff;
    font-weight: 700;
    font-size: 2.75rem;
}
.backgroundB[data-v-f99ecb42] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../static/img/background-bridge.be5032f.png);
}
.theFeatures[data-v-f99ecb42] {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #060642;
    background: linear-gradient(180deg, #060642 0%, #0e0031 100%);
}
.theFeatures .backgroundImg[data-v-f99ecb42] {
    position: relative;
    border-radius: 20px;
}
.theFeatures .contentFeature[data-v-f99ecb42] {
    position: absolute;
    bottom: 20px;
    left: 20px;
}
.theFeatures .contentFeature.positionTop[data-v-f99ecb42] {
    bottom: auto;
    top: 20px;
}
.theFeatures .contentFeature p[data-v-f99ecb42] {
    font-size: 1.125rem;
    color: rgba(255, 255, 255, 0.5);
}
.theFeatures .contentFeature .wbo-title[data-v-f99ecb42] {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
}
.auth-left-col[data-v-f99ecb42] {
    padding: 0 1rem;
}
.white-theme .link[data-v-f99ecb42] {
    text-decoration: underline !important;
}
.white-theme .opa-5[data-v-f99ecb42] {
    opacity: 1;
}
.white-theme a.colorWhite[data-v-f99ecb42] {
    text-decoration: none;
}
.white-theme a.colorWhite[data-v-f99ecb42]:hover {
    color: #2177ff;
}
.white-theme .button.btn-gray.btn-border[data-v-f99ecb42] {
    border: 1px solid #fff;
}
.white-theme .button.btn-gray.btn-border[data-v-f99ecb42]:hover {
    background-color: #fff;
}
.white-theme .md-field[data-v-f99ecb42] {
    padding-top: 10px;
    padding-bottom: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.white-theme .md-field .md-error[data-v-f99ecb42] {
    top: 100%;
}
.white-theme .md-field label[data-v-f99ecb42] {
    position: static;
}
.white-theme .md-field .md-input[data-v-f99ecb42],
.white-theme .md-field .md-textarea[data-v-f99ecb42] {
    width: 100%;
}
.white-theme .md-field label[data-v-f99ecb42] {
    position: static;
    padding: 0;
}
.white-theme .md-field .md-input[data-v-f99ecb42],
.white-theme .md-field .md-textarea[data-v-f99ecb42] {
    height: 40px;
    line-height: 40px;
}
.white-theme .logoLogin[data-v-f99ecb42] {
    display: block;
    height: 40px;
    width: 170px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
}
.white-theme .loginForm[data-v-f99ecb42] {
    color: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
}
.white-theme .loginForm a.colorWhite[data-v-f99ecb42] {
    font-style: italic;
    color: #000;
    margin-top: 0.25rem;
}
.white-theme .loginForm a.colorWhite[data-v-f99ecb42]:hover {
    background-color: #fff;
    color: #2177ff;
}
.bg-login[data-v-f99ecb42] {
    background-color: #fff !important;
}
@media (max-width: 610px) {
    .auth-left-col[data-v-f99ecb42] {
        width: 100% !important;
        min-height: 550px;
        overflow-y: scroll;
    }
    .auth-left-col .logoLogin[data-v-f99ecb42] {
        top: 15px !important;
    }
    .auth-right-col[data-v-f99ecb42] {
        display: none;
    }
    .backgroundLogin .auth-left-col div[data-v-f99ecb42] {
        justify-content: flex-start;
    }
    .backgroundLogin .auth-left-col div .loginForm[data-v-f99ecb42] {
        margin-top: 60px !important;
    }
    .bottomText[data-v-f99ecb42] {
        margin-bottom: 1.5rem !important;
        text-align: center;
    }
    .close-page[data-v-f99ecb42] {
        color: black;
        font-size: 45px;
        top: 10px;
        right: 10px;
    }
}
@media (max-width: 1024.98px) {
    .boxAuthentication .close-page[data-v-f99ecb42] {
        color: #fff;
        background-color: transparent;
    }
    .boxAuthentication .contentAuthentication[data-v-f99ecb42] {
        overflow: auto;
    }
    .boxAuthentication .mt5m[data-v-f99ecb42] {
        margin-top: 50px;
    }
}
